import { type SWRResponse } from 'swr';
import useSWRImmutable from 'swr/immutable';

import { type EnumsPageName } from '@lp-lib/api-service-client/public';

import { apiService } from '../../services/api-service';
import { type Tag, virtualTags } from '../../types';
import { PageManagerUtils } from '../PageManager/utils';
import { TagUtils } from './utils';

export function useFeaturedTags(
  context: EnumsPageName,
  withUntagged?: boolean | 'pinToTheTop',
  withPlayAgain?: boolean
): SWRResponse<Tag[], Error> {
  return useSWRImmutable(
    ['/tags/featured', context, withUntagged],
    async () => {
      const tags = (await apiService.tag.getFeaturedTags(context)).data.tags;
      if (withPlayAgain) {
        tags.push(virtualTags.playAgain);
      }
      if (withUntagged) {
        if (withUntagged === 'pinToTheTop') {
          tags.unshift(virtualTags.untagged);
        } else {
          tags.push(virtualTags.untagged);
        }
      }
      return tags;
    }
  );
}

export function useTag(idOrSlug: string) {
  return useSWRImmutable(`/tags/${idOrSlug}`, () =>
    TagUtils.GetTagByIdOrSlug(idOrSlug)
  );
}

export function useFeaturedPublicLibraryTags(pageName: EnumsPageName) {
  return useSWRImmutable(
    ['/featured-public-library-tags', pageName],
    async () => {
      const resp = await apiService.page.get(pageName);
      return PageManagerUtils.GetGamePackTags(resp.data.page);
    }
  );
}
